<template>
  <div class="initial-page">
    <div class="title-container">
      <h1><span>></span>{{ content.title[currentLocalize] }}</h1>
      <div class="subtitle-container">
        <h1>{{ content.subtitle[currentLocalize] }}</h1>
        <h2><span>></span>{{ content.subtitleDetails[currentLocalize] }}</h2>
      </div>
    </div>
    <div class="content-container">
      <div
        class="text-container"
        v-for="textObject in content.texts"
        :key="textObject"
      >
        <h2 v-if="textObject.header != null">
          <span>></span>{{ textObject.header[currentLocalize] }}
        </h2>
        <p v-html="textObject.description[currentLocalize]"></p>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "InitialPageContentSection",
  props: {
    content: Object,
    currentLocalize: String,
  }
};
</script>

<style scoped>
.initial-page {
  position: relative;
  left: 0px;
  top: 10px;
  width: 90%;
  height: 550px;
  margin: auto;
  padding: 0px 5px;
  color: #6c4d12;
  background: #dddcb1;
}
.title-container > *,
.subtitle-container > * {
  display: inline-block;
}
.title-container > h1,
.subtitle-container > h1 {
  font-size: 0.9em;
  font-weight: 500;
  margin: 0px;
}
.subtitle-container {
  float: right;
}
.subtitle-container > h2 {
  font-size: 0.9em;
  font-weight: normal;
  margin: 0px 10px;
}
.content-container{
    margin-top: 10px;
    overflow-y: auto;
    height: 460px;
    padding-right: 10px;
}
.text-container > *{
    margin: 5px 0px;
}
.text-container > h2 {
  font-size: 0.9em;
  font-weight: 500;
  text-transform: uppercase;
}
h1 > span {
    top: -2px;
    position: relative;
    padding-right: 5px;
}
h2 > span {
    color: red;
    top: -1px;
    position: relative;
    padding-right: 5px;
}
.text-container > p {
  color: black;
  font-size:0.8em;
}
</style>