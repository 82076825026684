<template>
  <div class="content-page">
    <div class="title-container">
      <table style="width: 100%">
        <tr>
          <th class="title-details">
            <h1>{{ getNumber() }}</h1>
            <span>></span>
          </th>
          <th class="title-details title">
            <h2 v-if="content.type === 'final_page'">
              <pre>{{ content.title[currentLocalize] }}</pre>
            </h2>
            <h2 v-else>{{ content.title[currentLocalize] }}</h2>
            <span v-if="content.subtitle != null">-</span>
          </th>
          <th class="title-details subtitle" v-if="content.subtitle != null">
            <h3 v-html="content.subtitle[currentLocalize]"></h3>
          </th>
          <th class="title-details more-text" v-if="content.moreText != null">
            <a v-on:click="toggleMoreText()">> {{moreTextLabel[currentLocalize]}}</a>
          </th>
        </tr>
      </table>
      <div class="title-more-text hidden">
        <a v-on:click="toggleMoreText()">X {{closeLabel[currentLocalize]}}</a>
        <div v-if="content.moreText != null && content.moreText.type==null" v-html="content.moreText[currentLocalize]"></div>
        <iframe v-if="content.moreText != null && content.moreText.type=='html'" v-bind:src="content.moreText[currentLocalize]"/>
      </div>
    </div>
    <div class="content-container" v-bind:class="{ column: isFlexColumn() }">
      <LineGallerySection
        v-if="content.type === 'line_gallery'"
        v-bind:content="content.content"
        v-bind:currentLocalize="currentLocalize"
      />
      <SingleGallerySection
        v-if="content.type === 'single_gallery'"
        v-bind:content="content.content"
        v-bind:panZoom="content.panZoom"
        v-bind:currentLocalize="currentLocalize"
      />
      <ScrollGallerySection
        v-if="content.type === 'scroll_gallery'"
        v-bind:content="content.content"
        v-bind:currentLocalize="currentLocalize"
      />
      <ColumnGallerySection
        v-else-if="content.type === 'column_gallery'"
        v-bind:content="content.content"
        v-bind:currentLocalize="currentLocalize"
      />
      <GridGallerySection
        v-else-if="content.type === 'grid_gallery'"
        v-bind:content="content.content"
        v-bind:currentLocalize="currentLocalize"
      />
      <FinalPageContentSection
        v-else-if="content.type === 'final_page'"
        v-bind:content="content.content"
        v-bind:currentLocalize="currentLocalize"
      />
      <SubpageContentSection
        v-else-if="content.type.includes('subpages')"
        v-bind:menuType="content.type"
        v-bind:content="content.content"
        v-bind:page="index + 1"
        v-bind:subpage="subindex + 1"
        v-bind:currentLocalize="currentLocalize"
        v-on:click-submenu="$emit('click-submenu', $event)"
      />
    </div>
    <!-- <div class="content-container">
      <div
        class="text-container"
        v-for="textObject in content.texts"
        :key="textObject"
      >
        <h2 v-if="textObject.header != null">
          <span>></span>{{ textObject.header[currentLocalize] }}
        </h2>
        <p v-html="textObject.description[currentLocalize]"></p>
      </div>
    </div> -->
  </div>
</template>



<script>
import LineGallerySection from "./content-sections/LineGallerySection.vue";
import SingleGallerySection from "./content-sections/SingleGallerySection.vue";
import ScrollGallerySection from "./content-sections/ScrollGallerySection.vue";
import ColumnGallerySection from "./content-sections/ColumnGallerySection.vue";
import GridGallerySection from "./content-sections/GridGallerySection.vue";
import SubpageContentSection from "./subpage-sections/SubpageContentSection.vue";
import FinalPageContentSection from "./content-sections/FinalPageContentSection.vue";
export default {
  name: "NormalPageContentSection",
  props: {
    content: Object,
    index: Number,
    subindex: Number,
    currentLocalize: String,
    moreTextLabel: Object,
    closeLabel: Object,
  },
  components: {
    SingleGallerySection,
    ScrollGallerySection,
    LineGallerySection,
    ColumnGallerySection,
    GridGallerySection,
    SubpageContentSection,
    FinalPageContentSection,
  },
  beforeUpdate: function () {
    this.resetMoreText();
  },
  emits: ["click-submenu"],
  methods: {
    getNumber: function () {
      return this.index < 10 ? "0" + this.index : this.index;
    },
    isFlexColumn: function () {
      return (
        this.content.type === "column_gallery" ||
        this.content.type.includes("subpages")
      );
    },
    toggleMoreText: function () {
      if (document.getElementsByClassName("title-more-text").length > 0) {
        let el = document.getElementsByClassName("title-more-text")[0];
        el.classList.toggle("hidden");

        let buttonEl = document.getElementsByClassName(
          "title-details more-text"
        );
        if (buttonEl.length > 0) buttonEl[0].classList.toggle("hidden");
      }
    },
    resetMoreText: function () {
      if (document.getElementsByClassName("title-more-text").length > 0) {
        let el = document.getElementsByClassName("title-more-text")[0];
        if (!el.classList.contains("hidden")) el.classList.toggle("hidden");

        let buttonEl = document.getElementsByClassName(
          "title-details more-text"
        );
        if (buttonEl.length > 0 && buttonEl[0].classList.contains("hidden"))
          buttonEl[0].classList.toggle("hidden");
      }
    },
  },
};
</script>

<style scoped>
.content-page {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0px;
  width: 850px;
  height: 550px;
  margin: auto;
  padding: 0px 5px;
  color: #6c4d12;
  /* background: #dddcb1; */
}
.title-container {
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
  padding: 0px 10px;
  color: black;
  background-color: white;
  position: relative;
}
.title-container > * {
  display: inline-block;
  vertical-align: middle;
}
.title-container > table {
  border-spacing: 0px;
  margin-top: 2px;
}
.title-details {
  vertical-align: top;
  padding: 0px;
}
.title-details.title {
  white-space: nowrap;
}
.title-details.title > h2{
  font-weight: bold;
}
.title-details > h2 > pre {
  margin: 0;
  font-family: inherit;
}
.title-details.subtitle {
  text-align: left;
}
.title-details > * {
  display: inline;
  vertical-align: top;
}
.title-details > h1,
.title-details > h2,
.title-details > h3,
.title-details > a {
  font-size: 0.8em;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}
.title-details > h1 {
  font-weight: bold;
}
.title-details > h2 {
  text-transform: uppercase;
}
.title-details > a {
  color: red;
  font-size: 0.8em;
  margin-left: 5px;
  white-space: nowrap;
  text-decoration: none;
}
.title-details.hidden > a {
  visibility: hidden;
}
.title-details > a:hover,
.title-more-text > a:hover {
  cursor: pointer;
  font-weight: bold;
}
.title-more-text {
  height: fit-content;
  position: absolute;
  width: calc(100% - 30px);
  background: white;
  left: 0px;
  top: 100%;
  margin-top: 10px;
  padding: 15px;
  font-size: 0.85em;
  z-index: 5;
}
.title-more-text.hidden {
  display: none;
}
.title-more-text > a {
  color: red;
  font-size: 0.7em;
  font-weight: 500;
  float: right;
  margin-top: -10px;
  margin-right: -5px;
}
.title-more-text > iframe{
  width:100%;
  border:none;
  height: 325px;
}
.content-container {
  margin-top: 10px;
  overflow-y: auto;
  flex-grow: 1;
  text-align: center;
  display: flex;
  height: 443px;
  overflow: auto;
}
.content-container.column {
  flex-direction: column;
}
.title-details > h1 + span {
  color: red;
  padding: 0px 5px;
  font-size: 1.3em;
  line-height: 18px;
}
.title-details > span {
  font-size: 1.2em;
  line-height: 21px;
  font-weight: 400;
  /* position: relative; */
  padding: 0px 3px;
  /* top: -6px; */
}
</style>