<template>
  <div class="two-column-gallery">
    <div class="left-column">
      <div>
        <img
          v-for="image in leftColumn1"
          :key="image"
          v-bind:src="this.baseUrl + image"
        />
      </div>
      <div>
        <img
          v-for="image in leftColumn2"
          :key="image"
          v-bind:src="this.baseUrl + image"
        />
      </div>
      <div>
        <img
          v-for="image in leftColumn3"
          :key="image"
          v-bind:src="this.baseUrl + image"
        />
      </div>
    </div>
    <div class="right-column" v-bind:class="{fadeIn: isLoading}">
      <h3>{{ content.right.name[currentLocalize] }}</h3>
      <img v-bind:src="content.right.content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoColumnGallerySection",
  props: {
    content: Object,
    currentLocalize: String,
  },
  data() {
    return {
      isLoading: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  watch: {
    content: function () {
      this.isLoading = false;
      let context = this;
      window.setTimeout(() => {
        context.isLoading = true;
      }, 100);
    },
  },
  computed: {
    leftColumn1: function () {
      return this.content.left.content.filter((item, i) => i < 5);
    },
    leftColumn2: function () {
      return this.content.left.content.filter((item, i) => i >= 5 && i < 9);
    },
    leftColumn3: function () {
      return this.content.left.content.filter((item, i) => i >= 9);
    },
  },
};
</script>

<style>
.two-column-gallery {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.two-column-gallery > div {
  flex-grow: 1;
}
.left-column {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.left-column > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-right: 25px;
  height: 78.7%;
}
.left-column > div:first-child{
    height: 100%;
}
.right-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  opacity: 0;
}
.right-column.fadeIn {
  animation: fadeIn 1s linear 1s forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.right-column > h3 {
  margin: 5px;
  color: black;
  font-size: 0.9em;
}
.right-column > img {
  height: 100%;
  width: fit-content;
  align-self: center;
}
</style>