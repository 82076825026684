<template>
  <div class="grid-gallery-column">
    <div class="grid-gallery-item">
        <img v-bind:src="this.baseUrl+content[0].path" />
        <p class="caption" v-if="content[0].caption" v-html="content[0].caption[currentLocalize]"></p>
    </div>
    <div class="grid-gallery-item">
        <img v-bind:src="this.baseUrl+content[1].path" />
        <p class="caption" v-if="content[1].caption" v-html="content[1].caption[currentLocalize]"></p>
    </div>
  </div>
  <div class="grid-gallery-column">
    <div class="grid-gallery-item">
        <img v-bind:src="this.baseUrl+content[2].path" />
        <p class="caption" v-if="content[2].caption" v-html="content[2].caption[currentLocalize]"></p>
    </div>
    <div class="grid-gallery-item">
        <img v-bind:src="this.baseUrl+content[3].path" />
        <p class="caption" v-if="content[3].caption" v-html="content[3].caption[currentLocalize]"></p>
    </div>
  </div>
<!-- <div class="grid-gallery-item" v-for="image in content" :key="image" v-bind:style="{ flexGrow: 1 }">
    <img v-bind:src="this.baseUrl+image.path" />
    <p v-if="image.caption">{{image.caption[currentLocalize]}}</p>
</div> -->
</template>

<script>
export default {
  name: "GridGallerySection",
  props: {
    content: Array,
    currentLocalize: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  }
}
</script>

<style>
  .grid-gallery-column{
    
        display: flex;
        flex-direction: column;
        flex-grow: 1;
  }
    .grid-gallery-item{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-self: center;
        padding: 0px 5px;
        max-height: 50%;
    }
    .grid-gallery-item > img{
      max-height: 100%;
    }
    .grid-gallery-item > p{
        color: black;
        font-weight: 500;
        margin: 10px 0.2em;
        height: 1.6em;
        line-height: 17px;
        font-size: 0.9em;
    }
    .grid-gallery-item > p > b{
      font-size: 1.2em;
    }
</style>