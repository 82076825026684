<template>
<div class="column-gallery-item" v-for="image in content" :key="image" v-bind:style="{ flexGrow: 1 }">
    <img v-bind:src="this.baseUrl+image.path" />
    <p class="caption" v-if="image.caption">{{image.caption[currentLocalize]}}</p>
</div>
</template>

<script>
export default {
  name: "ColumnGallerySection",
  props: {
    content: Array,
    currentLocalize: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  }
}
</script>

<style>
    .column-gallery-item{
        display: inline-block;
        align-self: center;
        padding: 0px 5px;
    }
    
  .subpage-content-container > .column-gallery-item{
    max-height: 40%;
    padding: 5px;
  }
    .column-gallery-item > img{
      max-height: 100%;
    }
    .column-gallery-item > p{
        color: black;
        font-weight: 500;
        margin: 0.2em;
        height: 1.6em;
        font-size: 0.9em;
    }
</style>