<template>
  <LanguageSection
    v-bind:localize="localize"
    v-bind:currentLocalize="currentLocalize"
    v-on:change-localize="changeLocalize($event)"
  />
  <WelcomePage
    v-if="isLoaded && currentPage == -1"
    v-bind:content="welcomePage"
    v-bind:currentLocalize="currentLocalize"
    v-on:open-content-page="openContentPage()"
  />
  <ContentPage
    v-if="isLoaded && currentPage >= 0"
    v-bind:currentPage="currentPage"
    v-bind:currentSubPage="currentSubPage"
    v-bind:content="content"
    v-bind:currentLocalize="currentLocalize"
    v-bind:moreTextLabel="moreText"
    v-bind:closeLabel="closeText"
    v-on:click-menu="changePage($event)"
    v-on:click-submenu="changeSubPage($event)"
  />
</template>

<script>
import LanguageSection from "../components/sections/LanguageSection.vue";
import WelcomePage from "../components/WelcomePage.vue";
import ContentPage from "../components/ContentPage.vue";
import axios from "axios";
export default {
  name: "ExercisesPage",
  data() {
    return {
      isLoaded: false,
      currentPage: -1,
      currentSubPage: 0,
      welcomePage: {},
      localize: [],
      currentLocalize: "pt",
      content: {},
      moreText: {},
      closeText: {},
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  components: {
    WelcomePage,
    ContentPage,
    LanguageSection,
  },
  created: function () {
    var context = this;
    axios.get(this.baseUrl + "/main.json").then((response) => {
      context.content = response.data.content;
      context.moreText = response.data.moreText;
      context.closeText = response.data.closeText;
      context.localize = response.data.localize;
      context.welcomePage = response.data.welcomePage;
      context.currentLocalize = context.localize[0];
      context.isLoaded = true;
      console.log(context.moreText);
    });
  },
  methods: {
    changeLocalize: function (event) {
      // `this` inside methods points to the Vue instance
      this.currentLocalize = event;
    },
    changePage: function (event) {
      // `this` inside methods points to the Vue instance
      this.currentPage = event;
      this.currentSubPage = 0;
      this.content[event].visited = true;
    },
    changeSubPage: function (event) {
      // `this` inside methods points to the Vue instance
      this.currentSubPage = event - 1;
    },
    openContentPage: function () {
      this.content[0].visited = true;
      this.currentPage = 0;
      this.currentSubPage = 0;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0%;
  font-family: "Saira", sans-serif;
  white-space: pre-line;
  background-color: #dddcb1;
}
#app {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
</style>
