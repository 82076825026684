<template>
  <div class="subtitle-section">
    <h3 class="subtitle-section text" v-if="checkForTitle()">
      {{ getTitle() }}
      <span v-if="checkForNumberedTitle()"> {{ subsubpage + 1 }}</span>
      {{ getAuthor() }}
    </h3>
    <NumberedMenuSection
      v-if="menuType === 'numbered_subpages'"
      v-bind:subpage="subpage"
      v-bind:count="content.length"
      v-on:click-submenu="$emit('click-submenu', $event)"
    />
  </div>
  <div class="content-and-menu-container">
    <div
      class="subpage-content-container"
      v-bind:class="{
        'grid-content': getCurrentContent().type === 'grid_gallery',
      }"
    >
      <SingleGallerySection
        v-if="getCurrentContent().type === 'single_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:panZoom="getCurrentContent().panZoom"
        v-bind:currentLocalize="currentLocalize"
      />
      <ScrollGallerySection
        v-if="getCurrentContent().type === 'scroll_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:currentLocalize="currentLocalize"
      />
      <LineGallerySection
        v-if="getCurrentContent().type === 'line_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:currentLocalize="currentLocalize"
      />
      <ColumnGallerySection
        v-else-if="getCurrentContent().type === 'column_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:currentLocalize="currentLocalize"
      />
      <GridGallerySection
        v-else-if="getCurrentContent().type === 'grid_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:currentLocalize="currentLocalize"
      />
      <TwoColumnSection
        v-else-if="getCurrentContent().type === 'two_column_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:currentLocalize="currentLocalize"
      />
      <TwoLineSection
        v-else-if="getCurrentContent().type === 'two_line_gallery'"
        v-bind:content="getCurrentContent().content"
        v-bind:currentLocalize="currentLocalize"
      />
      <p
        v-else-if="getCurrentContent().type === 'single_text_gallery'"
        v-html="getCurrentContent().content[currentLocalize]"
        class="single-text-item"
      />
    </div>

    <NamedMenuSection
      v-if="menuType === 'named_subpages'"
      v-bind:subpage="subpage"
      v-bind:content="content"
      v-on:click-submenu="$emit('click-submenu', $event)"
    />
    <NamedNumberedMenuSection
      v-if="menuType === 'named_numbered_subpages'"
      v-bind:subpage="subpage"
      v-bind:subsubpage="subsubpage"
      v-bind:content="content"
      v-bind:currentLocalize="currentLocalize"
      v-on:click-subsubmenu="onClickSubsubmenu($event)"
    />
  </div>
</template>

<script>
import NumberedMenuSection from "./menu-sections/NumberedMenuSection.vue";
import NamedNumberedMenuSection from "./menu-sections/NamedNumberedMenuSection.vue";
import NamedMenuSection from "./menu-sections/NamedMenuSection.vue";
import SingleGallerySection from "../content-sections/SingleGallerySection.vue";
import ScrollGallerySection from "../content-sections/ScrollGallerySection.vue";
import LineGallerySection from "../content-sections/LineGallerySection.vue";
import ColumnGallerySection from "../content-sections/ColumnGallerySection.vue";
import GridGallerySection from "../content-sections/GridGallerySection.vue";
import TwoColumnSection from "../content-sections/TwoColumnSection.vue";
import TwoLineSection from "../content-sections/TwoLineSection.vue";
export default {
  name: "SubpageContentSection",
  props: {
    content: Array,
    menuType: String,
    page: Number,
    subpage: Number,
    currentLocalize: String,
  },
  watch: {
    page: function () {
      this.subsubpage = 0;
    },
  },
  data() {
    return {
      subsubpage: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    checkForTitle: function () {
      if (this.menuType == "numbered_subpages")
        return this.content[this.subpage - 1].title != null;
      else return false;
    },
    checkForNumberedTitle: function () {
      return (
        this.menuType === "named_numbered_subpages" &&
        this.content[this.subpage - 1].content.length > 1
      );
    },
    getTitle: function () {
      if (this.menuType == "numbered_subpages")
        return this.content[this.subpage - 1].title[this.currentLocalize];
      else{
        if(this.content[this.subpage - 1].name[this.currentLocalize] != null)
          return this.content[this.subpage - 1].name[this.currentLocalize];
        else
          return this.content[this.subpage - 1].name;
      }
    },
    getAuthor: function () {
      if (this.menuType == "numbered_subpages") return "";
      else
        return this.content[this.subpage - 1].author != null
          ? " - " + this.content[this.subpage - 1].author
          : "";
    },
    getCurrentContent: function () {
      if (this.menuType == "named_numbered_subpages")
        return this.content[this.subpage - 1].content[this.subsubpage];
      else return this.content[this.subpage - 1];
    },
    onClickSubsubmenu: function (event) {
      if (this.subpage != event.subpage + 1)
        this.$emit("click-submenu", event.subpage + 1);
      this.subsubpage = event.subsubpage;
    },
  },
  components: {
    NumberedMenuSection,
    NamedNumberedMenuSection,
    NamedMenuSection,
    SingleGallerySection,
    ScrollGallerySection,
    LineGallerySection,
    ColumnGallerySection,
    GridGallerySection,
    TwoColumnSection,
    TwoLineSection,
  },
  emits: ["click-submenu"],
};
</script>

<style scoped>
.subtitle-section {
  width: 100%;
  position: relative;
  margin-bottom: 5px;
  height: 26px;
}
.subtitle-section.text {
  margin: 0px;
  color: black;
  font-size: 0.9em;
  text-align: left;
}
.subtitle-section.text > span {
  color: red;
  font-weight: 500;
  margin-left: 2px;
}
.subpage-content-container {
  flex-grow: 1;
  height: 400px;
  overflow: hidden;
  flex-direction: column;
    text-align: center;
    display: flex;
}
.subpage-content-container.grid-content {
  flex-direction: row;
  display: flex;
}
.content-and-menu-container {
  display: flex;
  flex-direction: row;
  height: 418px;
  max-height: 445px;
  overflow: hidden;
}
p.single-text-item{
  color: black;
  text-align: left;
  width: 90%;
}
</style>