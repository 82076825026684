<template>
  <div
    id="single-gallery-item"
    class="single-gallery-item"
    v-bind:style="{
      flexGrow: 1,
      'background-image': 'url(' + getImagePath() + ')',
      transform: transformImage(),
    }"
    @mousedown="onImageMouseDown()"
    @mouseup="onImageMouseUp()"
    @mouseout="onImageMouseUp()"
  >
    <!-- <div v-bind:style="{'background-image':'url('+image.path+')'}"> </div> -->
    <p class="caption" v-if="content[0].caption">
      {{ content[0].caption[currentLocalize] }}
    </p>
  </div>
  <div v-if="panZoom != null && panZoom === true" class="zoom-tools">
    <a v-on:click="activatePan()"><img src="images/tools/pan.png" /></a>
    <a v-on:click="activateZoomIn()"><img src="images/tools/zoomin.png" /></a>
    <a v-on:click="activateZoomOut()"><img src="images/tools/zoomout.png" /></a>
  </div>
</template>

<script>
import panZoomTools from "../../../utils/Constants";

export default {
  name: "SingleGallerySection",
  props: {
    content: Array,
    panZoom: Boolean,
    currentLocalize: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      currentTool: panZoomTools.NONE,
      singleImageEl: null,
      toolEvent: null,
      mouseProperties: null,
      imageProperties: panZoomTools.IMAGE_PROPERTIES,
    };
  },
  beforeCreate: function () {
    this.singleImageEl = document.getElementById("single-gallery-item");
  },
  watch: {
    content: function () {
      //if (this.panZoom == null || this.panZoom == false) return;
      this.imageProperties.zoom = 1;
      this.imageProperties.x = 0;
      this.imageProperties.y = 0;
      this.currentTool = panZoomTools.NONE;
      this.toolEvent = null;

      if (this.singleImageEl != null)
        this.singleImageEl.classList.remove("pan", "zoomIn", "zoomOut");
    },
  },
  methods: {
    getImagePath: function(){
      if(this.content[0].translated == true)
        return this.content[0].path[this.currentLocalize];
      else
        return this.content[0].path;
    },
    activateZoomIn: function () {
      this.checkSingleImageEl();
      this.currentTool = panZoomTools.ZOOMIN;
      this.singleImageEl.classList.add("zoomIn");
      this.singleImageEl.classList.remove("zoomOut");
      this.singleImageEl.classList.remove("pan");
    },
    activateZoomOut: function () {
      this.checkSingleImageEl();
      this.currentTool = panZoomTools.ZOOMOUT;
      this.singleImageEl.classList.add("zoomOut");
      this.singleImageEl.classList.remove("zoomIn");
      this.singleImageEl.classList.remove("pan");
    },
    activatePan: function () {
      this.checkSingleImageEl();
      this.currentTool = panZoomTools.PAN;
      this.singleImageEl.classList.add("pan");
      this.singleImageEl.classList.remove("zoomOut");
      this.singleImageEl.classList.remove("zoomIn");
    },
    checkSingleImageEl: function () {
      if (this.singleImageEl == null)
        this.singleImageEl = document.getElementById("single-gallery-item");
    },
    transformImage: function () {
      let rule = "scale(" + this.imageProperties.zoom + ")";
      rule +=
        " translate(" +
        this.imageProperties.x +
        "px, " +
        this.imageProperties.y +
        "px)";
      return rule;
    },
    onImageMouseDown: function () {
      if (this.panZoom == null || this.panZoom == false) return;
      let properties = this.imageProperties;
      switch (this.currentTool) {
        case panZoomTools.ZOOMIN:
          properties.zoom = Math.min(
            2,
            properties.zoom + panZoomTools.ZOOM_OFFSET
          );
          this.zoomEvent = setInterval(() => {
            properties.zoom = Math.min(
              2,
              properties.zoom + panZoomTools.ZOOM_OFFSET
            );
          }, 50);
          break;
        case panZoomTools.ZOOMOUT:
          properties.zoom = Math.max(
            0.75,
            properties.zoom - panZoomTools.ZOOM_OFFSET
          );
          this.zoomEvent = setInterval(() => {
            properties.zoom = Math.max(
              0.75,
              properties.zoom - panZoomTools.ZOOM_OFFSET
            );
          }, 50);
          break;
        case panZoomTools.PAN:
          this.singleImageEl.addEventListener(
            "mousemove",
            this.onImageMouseMove
          );
          break;
      }
    },
    onImageMouseUp: function () {
      if (this.panZoom == null || this.panZoom == false) return;
      if (this.zoomEvent != null) clearInterval(this.zoomEvent);
      if (this.singleImageEl != null)
        this.singleImageEl.removeEventListener(
          "mousemove",
          this.onImageMouseMove
        );
    },
    onImageMouseMove: function (e) {
      if (this.panZoom == null || this.panZoom == false) return;
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;

      this.imageProperties.x += e.movementX;
      this.imageProperties.y += e.movementY;
    },
  },
};
</script>

<style>
.single-gallery-item {
  display: flex;
  align-self: center;
  margin: 0px 5px;
  width: calc(100% - 10px);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
}
.single-gallery-item > img {
  max-width: 100%;
}
.single-gallery-item > p {
  color: black;
  font-weight: 500;
  margin: 0.2em;
  height: 1.6em;
  font-size: 0.9em;
  overflow-y: visible;
  width: 100%;
  align-self: flex-end;
  text-align: center;
}
.single-gallery-item.zoomIn {
  cursor: zoom-in;
}
.single-gallery-item.zoomOut {
  cursor: zoom-out;
}
.single-gallery-item.pan {
  cursor: grab;
}
.zoom-tools {
  position: absolute;
  background: white;
  margin-top: 10px;
  height: fit-content;
  bottom: 10px;
}
.zoom-tools > a {
  padding: 2px;
  cursor: pointer;
  display: inline-block;
  vertical-align: sub;
}
.zoom-tools > a > img {
  display: block;
  height: 20px;
  width: 20px;
}
</style>