<template>
  <div class="final-page-section">
    <a class="album-image-link"
      v-bind:href="getAlbumLink()">
      <img v-bind:src="baseUrl + content.path" />
    </a>
    <div class="text-container">
      <p v-html="content.text[currentLocalize]"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinalPageContentSection",
  props: {
    content: Object,
    currentLocalize: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods:{
    getAlbumLink: function(){
      return this.baseUrl + "memorias10/?loc=" + this.currentLocalize;
    }
  }
};
</script>

<style scoped>
.final-page-section {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 440px;
}
.text-container {
  height: 100%;
  overflow-y: scroll;
}
.text-container > p {
  color: black;
  font-size: 0.8em;
  text-align: left;
  margin: 0px;
  padding: 15px;
}
</style>