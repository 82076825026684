import { createRouter, createWebHistory } from "vue-router";
import ExercisesPage from "../views/ExercisesPage.vue";

const routes = [{ path:"/", component:ExercisesPage}];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
