<template>
  <div class="container welcome-page">
    <div class="full-logo welcome-page">
      <a v-on:click="$emit('open-content-page')">
        <img v-bind:src="content.welcomeImage[currentLocalize]" />
        <img class="hidden" v-bind:src="content.welcomeImageHover[currentLocalize]" />
      </a>
    </div>
    <div class="title welcome-page">
      <h1>{{ content.title[currentLocalize] }}</h1>
      <h2>{{ content.subtitle[currentLocalize] }}</h2>
    </div>
    <!-- <p class="description welcome-page">{{ content.description[currentLocalize] }}</p> -->
    <div class="logos-container welcome-page">
      <img src="../assets/logo_belas_artes_ulisboa.png" />
      <img src="../assets/ulisboa-logo.png" />
    </div>
    <div class="footer welcome-page">{{ content.technical_data[currentLocalize] }}</div>
  </div>
</template>


<script>
export default {
  name: "WelcomePage",
  props: {
    content: Object,
    currentLocalize: String
  },
};
</script>

<style scoped>
.welcome-page.container {
  position: relative;
  width: 940px;
  padding-top: 210px;
  margin: 140px auto 0px auto;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
}
.welcome-page.container > * {
  font-size: 0.95em;
  font-weight: 500;
}
.welcome-page.full-logo{
  position: absolute;
  top: 0px;
}
.welcome-page.full-logo,
.welcome-page.full-logo > a {
  text-align: center;
  width: inherit;
}
.welcome-page.full-logo > a > img {
  height: 220px;
  text-align: center;
}
.welcome-page.full-logo > a > img.hidden {
  display: none;
}
.welcome-page.full-logo > a:hover {
  cursor: pointer;
}
.welcome-page.full-logo > a:hover > img {
  display: none;
}
.welcome-page.full-logo > a:hover > img.hidden {
  display: inherit;
}
.welcome-page.title,
.welcome-page.description,
.welcome-page.logos-container,
.welcome-page.footer {
  margin: 0px 45px 30px 45px;
  line-height: 1em;
}
.welcome-page.title > * {
  font-size: 0.92em;
  font-weight: 500;
  margin: 0px;
}
.welcome-page.description {
  font-size: 0.8em;
  line-height: 1.3em;
}
.welcome-page.footer {
  margin: 0px 20px 0px 45px;
  font-size: 0.72em;
  line-height: 1.3em;
}
.welcome-page .logos-container {
  text-align: right;
}
.welcome-page .logos-container > img {
  height: 70px;
  margin-left: 35px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>