<template>
  <div class="menu-section">
    <div class="menu-section border"></div>
    <div class="menu-section button-container">
      <a
        v-for="(topic, index) in content"
        :key="topic"
        v-on:click="topic.title == null ? '' : $emit('click-menu', index)"
        v-bind:class="{
          visited: topic.visited == true,
          disabled: topic.title == null,
        }"
      >
        {{ getNumber(index) }}
        <span v-if="topic.title != null" class="hover-menu-item">{{
          topic.title[currentLocalize]
        }}</span>
      </a>
    </div>
  </div>
</template>


<script>
export default {
  name: "MenuSection",
  props: {
    content: Array,
    currentPage: Number,
    currentLocalize: String
  },
  emits: ["click-menu"],
  methods: {
    getNumber: function (index) {
      return index < 10 ? "0" + index : index;
    },
  },
};
</script>

<style scoped>
.menu-section {
  position: relative;
  height: 80px;
}
.menu-section.border {
  position: absolute;
  top: 16px;
  border: 1px solid white;
  width: 100%;
  height: 75px;
  left: 0px;
  z-index: -1;
}
.menu-section.button-container {
  width: 96%;
  margin: auto;
  background: #dddcb1;
}
.menu-section > a {
  display: inline-block;
  background-color: #5b86c1;
  border: 2px solid #5b86c1;
  color: white;
  margin: 8px 3px;
  padding: 0px;
  width: 17px;
  text-align: center;
  font-size: 0.75em;
  line-height: 15px;
  user-select: none;
  position: relative;
}
.menu-section > a.visited {
  background-color: #b8bfc7;
  border: 2px solid #b8bfc7;
}
.menu-section > a.disabled {
  background-color: lightgray;
  border: 2px solid lightgray;
  font-style: italic;
  font-size: 0.7em;
  color: grey;
}
.menu-section > a.disabled:hover {
  background-color: lightgray;
  border: 2px solid lightgray;
  cursor: auto;
}
.menu-section > a:last-child.visited {
  background-color: #f1b383;
  border: 2px solid #f1b383;
}
.menu-section > a:hover {
  cursor: pointer;
  background-color: #5f4623;
  border: 2px solid white;
}
.menu-section > a:last-child {
  background-color: #ff6600;
  border: 2px solid #ff6600;
  color: white;
}
.menu-section > a:last-child:hover {
  cursor: pointer;
  background-color: #ff6600;
  border: 2px solid white;
}
.hover-menu-item {
  display: none;
  position: absolute;
  top: -30px;
  left: -50%;
  color: black;
  word-break: keep-all;
  white-space: nowrap;
  background-color: #dddcb1;
  text-transform: uppercase;
  padding: 5px 7px;
}
.menu-section > a:hover > .hover-menu-item {
  display: block;
}
</style>