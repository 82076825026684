const panZoomTools = {
    NONE: 1,
    PAN: 2,
    ZOOMIN: 3,
    ZOOMOUT: 4,
    ZOOM_OFFSET: 0.05,
    IMAGE_PROPERTIES:{
        zoom: 1,
        x: 0,
        y: 0
    }
};
export {panZoomTools as default}