<template>
  <div class="named-menu">
    <a
      v-for="(element, i) in content"
      v-bind:key="i"
      v-bind:class="{ selected: this.subpage == i+1 }"
      v-on:click="$emit('click-submenu', i + 1)"
    >
      <span>></span>{{ element.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: "NamedMenuSection",
  props: {
    subpage: Number,
    content: Array,
  },
  emits: ["click-submenu"]
};
</script>
<style>
.named-menu {
  width: fit-content;
  float: right;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 5px;
  max-width: 300px;
}
.named-menu > * {
  color: red;
  margin: 4px;
  white-space: nowrap;
  font-size: 0.9em;
}
.named-menu > a:hover {
  cursor: pointer;
  font-weight: 500;
}
.named-menu > a.selected {
  color: black;
  font-weight: 700;
}
.named-menu > a > span{
  margin-right: 3px;
  line-height: 21px;
  font-size: 1.2em;
}
</style>