<template>
<div class="language-section">
    <a 
    v-for="loc in localize" 
    :key="loc" 
    v-on:click="$emit('change-localize',loc)" 
    v-bind:class="{selected: currentLocalize == loc}">{{loc}}</a>
</div>
</template>


<script>
export default {
  name: "LanguageSection",
  props: {
    localize: Array,
    currentLocalize: String
  },
  emits:['change-localize']
};
</script>

<style scoped>
    .language-section{
        position: absolute;
        top: 10px;
        right:15px;
    }
    .language-section>a{
        padding: 5px;
    }
    .language-section>a.selected{
        font-weight: bold;
    }
    .language-section>a:hover{
        cursor: pointer;
        font-weight: bold;
    }
</style>