<template>
  <div class="two-line-gallery">
    <div class="top-line">
      <h3>{{ content.top.name[currentLocalize] }}</h3>
      <div>
        <lineGallerySection
          v-bind:content="content.top.content"
          v-bind:currentLocalize="currentLocalize"
        />
      </div>
    </div>
    <div class="bottom-line" v-bind:class="{fadeIn: isLoading}">
      <h3>{{ content.bottom.name[currentLocalize] }}</h3>
      <img v-bind:src="content.bottom.content" />
      <p class="caption" >{{ content.bottom.caption[currentLocalize] }}</p>
    </div>
  </div>
</template>

<script>
import LineGallerySection from "./LineGallerySection.vue";
export default {
  components: { LineGallerySection },
  name: "TwoLineSection",
  props: {
    content: Object,
    currentLocalize: String,
  },
  watch: {
    content: function () {
      this.isLoading = false;
      let context = this;
      window.setTimeout(() => {
        context.isLoading = true;
      }, 100);
    },
  },
  data() {
    return {
      isLoading: true,
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
};
</script>

<style>
.two-line-gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.top-line > h3,
.bottom-line > h3 {
  margin: 0px 0px 5px 0px;
  color: black;
  font-size: 0.9em;
}
.top-line > div {
  position: relative;
  height: 185px;
  display: flex;
  justify-content: space-evenly;
}
.bottom-line {
  opacity: 0;
  margin-top: 5px;
}
.bottom-line.fadeIn {
  animation: fadeIn 1s linear 1s forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bottom-line > * {
  margin: 0px;
}
.bottom-line > p {
  color: black;
  font-weight: 500;
  font-size: 0.9em;
  position: absolute;
  width: 100%;
}
.bottom-line > img {
  height: 68%;
  width: auto;
}
</style>