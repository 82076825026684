<template>
  <div class="scroll-gallery-item" v-bind:style="{ flexGrow: 1 }">
    <!-- <img v-bind:src="content[0].path"/> -->
    <img v-bind:src="content[0].path" />
    <p class="caption" v-if="content[0].caption">
      {{ content[0].caption[currentLocalize] }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ScrollGallerySection",
  props: {
    content: Array,
    currentLocalize: String,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  beforeUpdate: function () {
    let scrollEl = document.getElementsByClassName("scroll-gallery-item");
    if (scrollEl.length > 0) scrollEl[0].scrollLeft = 0;
  },
};
</script>

<style>
.scroll-gallery-item {
  display: flex;
  align-self: center;
  width: calc(100% - 10px);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  overflow-x: scroll;
  overflow-y:hidden;
}
.scroll-gallery-item::-webkit-scrollbar{
  background-color: white;
  border-radius: 0px;
  width: 20px;
}
.scroll-gallery-item::-webkit-scrollbar-thumb{
  background-color: grey;
  border-radius: 10px;
  border: 5px solid white;
}

.scroll-gallery-item::-webkit-scrollbar-thumb:vertical{
  height: 20px!important;
  width: 20px;
}

.scroll-gallery-item > img {
  height: 100%;
}
.scroll-gallery-item > p {
  color: black;
  font-weight: 500;
  margin: 0.2em;
  height: 1.6em;
  overflow-y: visible;
  width: 100%;
  align-self: flex-end;
  text-align: center;
  font-size: 0.9em;
}
</style>