<template>
  <div class="named-numbered-menu">
    <div v-for="(element, i) in content" v-bind:key="i" class="named-submenu">
      <p
        class="selectable"
        v-on:click="
          element.content.length == 1 || this.subpage != i + 1
            ? $emit('click-subsubmenu', { subpage: i, subsubpage: 0 })
            : ''
        "
        v-bind:class="{
          selected: this.subpage == i + 1,
        }"
      >
        <span>{{ element.content.length == 1 ? ">" : "  " }}</span>
        {{
          element.name[currentLocalize] != null
            ? element.name[currentLocalize]
            : element.name
        }}
      </p>
      <div v-if="element.content.length != 1">
        <span>></span>
        <a
          v-for="(subelement, j) in element.content"
          v-bind:key="j"
          v-bind:class="{
            selectable: this.subpage == i + 1,
            selected: this.subpage == i + 1 && this.subsubpage == j,
          }"
          v-on:click="$emit('click-subsubmenu', { subpage: i, subsubpage: j })"
        >
          {{ j + 1 }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NamedMenuSection",
  props: {
    subpage: Number,
    subsubpage: Number,
    content: Array,
    currentLocalize: String,
  },
  emits: ["click-subsubmenu"],
};
</script>
<style>
.named-numbered-menu {
  float: right;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  min-width: 160px;
  max-width: 160px;
}
.named-submenu > p,
.named-submenu > div > a {
  color: black;
  margin: 4px;
  white-space: normal;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
}
.named-submenu > p.selectable,
.named-submenu > div {
  margin-bottom: 10px;
}

.named-submenu > p.selectable,
.named-submenu > div > a.selectable,
.named-submenu > div > span {
  position: relative;
  color: red;
}
.named-submenu > p.selectable > span {
  position: absolute;
  left: -15px;
}
.named-submenu > p.selectable:hover,
.named-submenu > div > a.selectable:hover {
  cursor: pointer;
  font-weight: 700;
}
.named-submenu > p {
  margin: 0px;
}
.named-submenu > p.selected,
.named-submenu > div > a.selected {
  color: black;
  font-weight: 700;
}

.named-submenu > p + div > span{
  color: black;
}
</style>