<template>
<div class="content-container">
    <MenuSection 
      v-bind:content="content"
      v-bind:currentLocalize="currentLocalize"
      v-on:click-menu="$emit('click-menu', $event)"/>
    <InitialPageContentSection 
      v-if="showInitialPage()"
      v-bind:content="content[currentPage]"
      v-bind:currentLocalize="currentLocalize"/>
      <NormalPageContentSection 
      v-else
      v-bind:index="currentPage"
      v-bind:subindex="currentSubPage"
      v-bind:content="content[currentPage]"
      v-bind:currentLocalize="currentLocalize"
      v-bind:moreTextLabel="moreTextLabel"
      v-bind:closeLabel="closeLabel"
      v-on:click-submenu="$emit('click-submenu', $event)"/>
</div>
</template>

<script>

import MenuSection from "./sections/MenuSection.vue";
import InitialPageContentSection from "./sections/InitialPageContentSection.vue";
import NormalPageContentSection from "./sections/NormalPageContentSection.vue";

export default {
    name: "ContentPage",
    props: {
    content: Array,
    currentPage: Number,
    currentSubPage: Number,
    currentLocalize: String,
    moreTextLabel: Object,
    closeLabel: Object,
  },
  emits:['click-menu', 'click-submenu'],
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  components: {
    MenuSection,
    InitialPageContentSection,
    NormalPageContentSection
  },
  methods:{
    showInitialPage: function(){
      return this.currentPage>=0 && this.content[this.currentPage].type=='initial_page';
    }
  }
}
</script>

<style scoped>
    .content-container{
        position:relative;
        width: 940px;
        margin: 40px auto 0px auto;
    }
    .content-container>h2{
      margin: 30px 10px;
      font-weight: 500;
      font-size: 1em;
      font-style: italic;
      color: #5f4623;
    }
</style>