<template>
  <div class="numbered-menu">
    <span>></span>
    <a  v-for="i in count"
        v-bind:key="i"
        v-bind:class="{selected: subpage == i}"
        v-on:click="$emit('click-submenu', i)">
        {{ i }}
    </a>
  </div>
</template>

<script>
export default {
  name: "NumberedMenuSection",
  props: {
    subpage: Number,
    count: Number,
  },
  emits: ["click-submenu"]
};
</script>
<style>
.numbered-menu {
  width: fit-content;
  position: absolute;
  right: 0px;
  top: 0px;
}
.numbered-menu > * {
  color: red;
  margin: 4px;
}
.numbered-menu > a:hover{
    cursor: pointer;
    font-weight: 500;
}
.numbered-menu > a.selected {
    color: black;
    font-weight: 700;
}
</style>